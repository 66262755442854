<template>
  <v-row
    class="mx-5"
    justify="space-between"
    dense
  >
    <SectionTitle title="Produtos">
      <template slot="action-buttons">
        <v-btn
          class="mr-2"
          x-large
          :disabled="readOnlyOrderInvoicingTab"
          color="primary"
          icon
          @click="() => (isExpanded = !isExpanded)"
        >
          <v-icon size="20">
            {{
              isExpanded
                ? 'fas fa-compress-arrows-alt'
                : 'fas fa-expand-arrows-alt'
            }}
          </v-icon>
        </v-btn>
      </template>
    </SectionTitle>
    <v-col
      class="elevation-3 pa-0"
      cols="12"
      md="12"
    >
      <InvoicingProductsTable
        :products="order_products"
        button-text="Adicionar"
        :is-expanded="isExpanded"
        @addProductAction="addProduct"
      />
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <SectionTitle title="Notas Fiscais" />
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-text-field
        v-model="number"
        label="NF"
        :disabled="!readOnlyOrderInvoicingTab"
        placeholder="N° da nota fiscal"
        rounded
        dense
        outlined
        :readonly="is_disabled"
      />
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-text-field
        v-model="emission_date"
        v-mask="['##/##/####']"
        label="Emissão"
        placeholder="Data de emissão"
        rounded
        :disabled="!readOnlyOrderInvoicingTab"
        :readonly="is_disabled"
        dense
        outlined
      />
    </v-col>
    <v-col
      class="d-flex"
      cols="12"
      md="6"
    >
      <v-select
        v-model="operation"
        label="Natureza da operação"
        :items="operation_natures"
        :item-value="'name'"
        :item-text="'name'"
        rounded
        :disabled="!readOnlyOrderInvoicingTab"
        dense
        outlined
        :readonly="is_disabled"
      />
    </v-col>
    <v-col>
      <v-btn
        :disabled="
          isAddDisabled || !showEditOrders || !readOnlyOrderInvoicingTab
        "
        block
        class="primary ml-2"
        rounded
        @click="addReceipt"
      >
        adicionar NF
        <v-icon
          small
          right
        >
          fas fa-plus
        </v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-progress-linear
        v-if="isLoadingReceipts"
        indeterminate
        color="primary"
        class="ma-4"
      />
      <v-expansion-panels
        v-else
        v-model="panel"
        focusable
        mandatory
      >
        <Receipt
          v-for="(receipt, idx) in receipts"
          :key="idx"
          :receipt="receipt"
          :receipt_products="order_products"
          @removeProduct="removeProduct"
          @setReceipt="(e) => setReceipt(idx, e)"
          @invoicedNF="invoicedNF"
        />
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    InvoicingProductsTable: () => import('./InvoicingProductsTable'),
    SectionTitle: () => import('@/components/SectionTitle'),
    Receipt: () => import('./Receipt.vue'),
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    operation_natures: Array,
  },
  data: () => ({
    isExpanded: false,
    showCustomItem: null,
    number: null,
    emission_date: null,
    operation: null,
    value: null,
    panel: null,
    invoices: [],
    receipts: [],
    currentReceipt: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    products: [],
    is_disabled: null,
    isLoadingReceipts: false,
  }),
  watch: {
    receipts: {
      handler(newvalue) {
        this.$emit('setReceipts', newvalue)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('comercial', ['showEditOrders', 'readOnlyOrderInvoicingTab']),
    order_products() {
      this.setOrderProducts()
      return this.order.order_products
    },
    isAddDisabled() {
      if (!this.number || !this.emission_date || !this.operation) {
        return true
      }
      return false
    },
  },
  created() {
    // if (this.order.nf.order_nf) {
    //   this.receipts = this.order.nf.order_nf
    // }
    this.is_disabled = this.$cookies.get('profile') == 5 ? true : false

    this.getInvocings()
  },
  methods: {
    setOrderProducts() {
      this.order.budget.products.map((budget_product) => {
        this.order.order_products.map((order_product) => {
          if (order_product.budget_product_id === budget_product.id) {
            if (budget_product.is_temporary) {
              order_product.image = budget_product.product.temp_image
                ? budget_product.product.temp_image
                : null
              order_product.reference = budget_product.product.id
              order_product.name = budget_product.product.product_name
            } else {
              order_product.image = budget_product.product_images_selected_color
                ? budget_product.product_images_selected_color.src
                : null
              order_product.color = budget_product.color.name
              order_product.color_img = budget_product.color.image_color
              order_product.reference = budget_product.product.product_reference
              order_product.name = budget_product.product.product_name
              order_product.print =
                budget_product.vendor_finance_id == 1
                  ? budget_product.print.name
                  : budget_product.product_vendor_selected_cost_table.title
            }
          }
        })
      })
    },
    invoicedNF() {
      this.$emit('invoiced')
      this.getInvocings()
    },
    setReceipt(idx, value) {
      this.receipts[idx] = value
    },
    updateOrder() {
      this.order.nf.order_nf = this.receipts
    },
    getInvocings() {
      this.isLoadingReceipts = true
      this.$api
        .get(`orders/invoicings/${this.order.id}`)
        .then((res) => {
          this.receipts = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoadingReceipts = false
        })
    },

    addReceipt() {
      this.receipts.push({
        order_id: this.order.id,
        code: this.number,
        operation_nature: this.operation,
        emission_date: this.emission_date,
        value: 0,
        deadlines: [],
        products: [],
      })

      this.operation = null
      this.emission_date = ''
      this.number = null
    },
    addProduct(product) {
      if (this.receipts.length == 0) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Por favor, selecione uma Nota Fiscal',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })

        return
      }

      if (this.receipts[this.panel].is_invoiced) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Algo deu errado',
          caption: 'Esta nota fiscal já foi faturada',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })

        return
      }

      if (
        this.receipts[this.panel].products.find(
          (el) => el.id === product.id || el.order_product_id === product.id
        )
      ) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Algo deu errado',
          caption: 'Este produto já foi adicionado à esta nota fiscal',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })

        return
      }

      this.receipts[this.panel].products.push(product)
    },
    removeProduct(obj) {
      let product = obj.product
      let quantity = parseInt(obj.quantity)
      if (product.done_quantity == 0) {
        let idx = this.receipts.indexOf(this.currentReceipt)
        let product_idx = this.receipts[idx].products.indexOf(product)
        this.receipts[idx].products.splice(product_idx, 1)
      }

      var prod = this.order.nf.order_product.find(
        (nf_product) => nf_product.id == product.order_product_id
      )
      prod.done_quantity = prod.done_quantity - quantity
    },
  },
}
</script>
